import { useCallback } from 'react';

import { useFlags } from '~/utils/feature-flags';
import { useLazyQuery } from '~/utils/graphql';

import { GET_ANSWER_QUERY } from '~/queries/get-answer-query';
import { GET_VOUCH_ANSWERS_CROPPING_QUERY } from '~/queries/get-vouch-answers-cropping-query';

import type { Answer } from '~/utils/codegen/graphql';

function useGetAnswerVouchAnswers() {
  const flags = useFlags(['answer-vouches']);

  const answerVouchesEnabled = flags?.['answer-vouches']?.enabled;

  const [fetchAnswer] = useLazyQuery(GET_ANSWER_QUERY);
  const [fetchVouch] = useLazyQuery(GET_VOUCH_ANSWERS_CROPPING_QUERY);

  const getAnswers = useCallback(
    async ({ ids }: { ids: string[] }) => {
      let answers;
      if (answerVouchesEnabled) {
        const res = await Promise.all(ids.map((id) => fetchVouch({ variables: { id } })));
        const items = res.map((r) => r.data?.vouch);
        answers = items.flatMap((item) => {
          const questions = item?.questions?.items;
          return questions?.map((question: any) => ({
            id: question?.answer?.id,
            settings: question?.answer?.settings
          }));
        });
      } else {
        const res = await Promise.all(ids.map((id) => fetchAnswer({ variables: { id } })));
        const items = res.map((r) => r.data?.answer as RecursivePartial<Answer>);
        answers = items.map((item) => ({ id: item?.id, settings: item?.settings }));
      }

      return answers.map((answer) => ({
        id: answer?.id,
        settings: {
          endOffset: answer?.settings?.endOffset,
          startOffset: answer?.settings?.startOffset,
          crop: {
            x: answer?.settings?.crop?.x,
            y: answer?.settings?.crop?.y,
            width: answer?.settings?.crop?.width,
            height: answer?.settings?.crop?.height
          }
        }
      }));
    },
    [answerVouchesEnabled, fetchVouch, fetchAnswer]
  );

  return { getAnswers };
}

export { useGetAnswerVouchAnswers };
