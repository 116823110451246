import { gql } from '~/utils/codegen';

export const GET_ANSWER_QUERY = gql(/* GraphQL */ `
  query GetAnswer($id: ID!) {
    answer(id: $id) {
      ...VideoAnswer
      id
      duration
      vouch {
        id
        questions {
          items {
            id
          }
        }
      }
      question {
        id
      }
      transcription {
        items {
          type
          start_time
          end_time
          speaker_label
          alternatives {
            content
          }
        }
      }
    }
  }
`);
