import { gql } from '~/utils/codegen';

export const GET_ACCOUNT_AND_ENTITY_QUERY = gql(/* GraphQL */ `
  query GetAccountAndEntity($id: ID!, $entityId: ID!) {
    account(id: $id) {
      id
      email
      name
      status
      avatarSrc
      showOnboarding
      role
      useCases
      userId
      createdAt
      claims
    }
    entity(id: $entityId) {
      id
      type
      name
      hashId
      spaceId
      parentId
      logoSrc
      siteUrl
      showUpgrade
      keys {
        api
        embed
      }
      billing {
        status
        trial {
          start
          end
        }
      }
    }
  }
`);
