import { forwardRef, useCallback, useState } from 'react';

import { Button } from '~/components/button';
import { Icon } from '~/components/icon';
import { Link } from '~/utils/routing/Link';
import { styled, truncate } from '~/utils/styling';

import type { ComponentProps, ForwardedRef, MouseEvent } from 'react';

const Container = styled('div', {
  fontSize: '$small',
  fontWeight: '$bold',
  maxWidth: '100%',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$wee',
  border: '0 none',
  borderRadius: '9999rem',
  height: '$$stickerHeight',
  lineHeight: '$$stickerHeight',
  padding: '0 $$stickerPadding',
  background: '$$stickerBackground',
  color: '$$stickerColor',

  '&, &:hover, &:focus': {
    textDecoration: 'none',
    background: '$$stickerBackground',
    color: '$$stickerColor'
  },

  '&[data-interactive=true]': {
    '&:hover, &:focus': {
      background: '$$stickerHoverBackground'
    }
  },

  // TODO: pull out variants and compound variants for reusable container props,
  // e.g. right now these are exactly the same in `Tag`
  // https://vouch.atlassian.net/browse/VCH-2520
  variants: {
    size: {
      small: {
        $$stickerHeight: '1.1rem',
        $$stickerPadding: '$space$tiny'
      },
      medium: {
        $$stickerHeight: '$sizes$tiny',
        $$stickerPadding: '$space$tiny'
      }
    },
    color: {
      neutral: {
        $$stickerColor: '$colors$dark-800', // TODO: add interactive color to other variants
        $$stickerBackground: '$colors$dark-80',
        $$stickerHoverBackground: '$colors$dark-200'
      },
      teal: {
        $$stickerColor: '$colors$teal-600',
        $$stickerBackground: '$colors$teal-100'
      },
      purple: {
        $$stickerColor: '$colors$purple-700',
        $$stickerBackground: '$colors$purple-100'
      },
      red: {
        $$stickerColor: '$colors$red-900',
        $$stickerBackground: '$colors$red-100'
      },
      brand: {
        $$stickerColor: '$colors$s-brand-50',
        $$stickerBackground: '$colors$s-brand-500'
      }
    },

    strong: { true: {} },

    truncate: {
      true: {
        // NOTE: We nest element as children of the sticker sometimes and those should also be truncated
        wordBreak: 'break-all',
        ...truncate({ lines: 1 })
      },
      false: {
        whiteSpace: 'nowrap'
      }
    },

    loading: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none'
      }
    }
  },

  compoundVariants: [
    {
      color: 'neutral',
      strong: true,
      css: {
        $$stickerColor: '$colors$grey-100',
        $$stickerBackground: '$colors$dark-800',
        $$stickerHoverBackground: '$colors$dark-1000'
      }
    },
    {
      color: 'teal',
      strong: true,
      css: {
        $$stickerColor: '$colors$teal-50',
        $$stickerBackground: '$colors$teal-600'
      }
    },
    {
      color: 'purple',
      strong: true,
      css: {
        $$stickerColor: '$colors$purple-100',
        $$stickerBackground: '$colors$purple-600'
      }
    },
    {
      color: 'red',
      strong: true,
      css: {
        $$stickerColor: '$colors$red-50',
        $$stickerBackground: '$colors$red-500'
      }
    },
    {
      color: 'brand',
      strong: true,
      css: {
        $$stickerColor: '$colors$s-brand-50',
        $$stickerBackground: '$colors$s-brand-600'
      }
    }
  ],

  defaultVariants: {
    color: 'neutral',
    size: 'medium',
    truncate: false
  }
});

const StyledIcon = styled(Icon, {
  height: '1.2em',
  width: 'auto'
});

const Actions = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '.1rem'
});

const ActionButton = styled(Button, {
  '&&': {
    background: 'transparent',
    border: '0 none',
    borderRadius: '50%',
    width: 'calc($$stickerHeight - .2rem)',
    height: 'calc($$stickerHeight - .2rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 calc(-$$stickerPadding + .1rem) 0 -.1rem',
    padding: 0,
    fontSize: 'inherit',

    '&:hover, &:focus': {
      background: '$dark-80'
    },

    '& svg': {
      height: '1em',
      width: 'auto'
    }
  }
});

type Action = {
  icon: ComponentProps<typeof Icon>['name'];
  tooltip?: string;
  onClick: (e: MouseEvent) => any | Promise<any>;
};

type StickerProps = Omit<
  ComponentProps<typeof Container> & Partial<ComponentProps<typeof Link>>,
  'onClick' | 'color'
> & {
  color?: ComponentProps<typeof Container>['color'];
  icon?: ComponentProps<typeof Icon>['name'];
  onClick?: (e: MouseEvent) => any | Promise<any>;
  actions?: Action[];
};

const Sticker = forwardRef(function Sticker(
  { icon, actions, children, ...props }: StickerProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  // Custom loading state for the button, cause the default loading dots are too big :|
  const [loading, setLoading] = useState(false);
  const handleAction = useCallback((onClick: Action['onClick'], e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const res = onClick?.(e);
    if (res?.finally) {
      setLoading(true);
      res?.finally?.(() => setLoading(false));
    }
  }, []);

  if ('href' in props && props.href) {
    const { href, ...rest } = props;
    return (
      <Container as={Link as any} ref={ref} href={href} data-interactive={true} loading={loading} {...rest}>
        {icon && <StyledIcon name={icon} />}
        {children}
        {actions && (
          <Actions>
            {actions.map((action, i) => (
              <ActionButton
                key={`${action.icon}-${i}`}
                color="grey"
                tooltip={action.tooltip}
                onClick={(e) => handleAction(action.onClick, e)}
              >
                <Icon name={action.icon} />
              </ActionButton>
            ))}
          </Actions>
        )}
      </Container>
    );
  }

  return (
    <Container
      ref={ref}
      as={props.onClick ? 'button' : undefined}
      data-interactive={!!props.onClick}
      loading={loading}
      {...props}
    >
      {icon && <StyledIcon name={icon} />}
      {children}
      {actions && (
        <Actions>
          {actions.map((action, i) => (
            <ActionButton
              key={`${action.icon}-${i}`}
              color="grey"
              tooltip={action.tooltip}
              onClick={(e) => handleAction(action.onClick, e)}
            >
              <Icon name={action.icon} />
            </ActionButton>
          ))}
        </Actions>
      )}
    </Container>
  );
});

export { Sticker };
